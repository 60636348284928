<template>
  <LoginPresentation :is-error="isError" @signin="handleSignin" />
</template>

<script>
import LoginPresentation from '@/components/auth/LoginPresentation.vue'
import { cognitoSignIn } from '@/assets/modules/cognito'
import store from '@/store'

export default {
  name: 'Login',
  components: { LoginPresentation },
  metaInfo() {
    return {
      title: this.$t('ログイン'),
    }
  },
  data() {
    return {
      isError: false,
    }
  },
  beforeRouteEnter(to, _from, next) {
    // ログイン済みの場合トップにリダイレクト
    if (store.getters['auth/isLoggedIn']) {
      next({
        name: 'Index',
        params: {
          portalId: to.params.portalId,
        },
      })
      return
    }
    next()
  },
  methods: {
    async handleSignin({ username, password, keepLogin }) {
      const { redirect } = this.$route.query
      const result = await cognitoSignIn(username, password, keepLogin)

      if (result.isError) {
        if (result.data.code === 'UserNotConfirmedException') {
          this.$router.push({
            name: 'AccountVerifications',
            query: { username, redirect },
          })
          return
        }

        this.isError = true
        return
      }

      this.$router.push(
        redirect
          ? redirect
          : { name: 'Index', params: { portalId: this.portalId } }
      )
    },
  },
}
</script>
