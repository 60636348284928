var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"l-container"},[(_vm.isSecurityUserAccess)?_c('div',{staticClass:"p-login"},[_c('div',{staticClass:"p-login__column"},[_c('form',{staticClass:"p-authSection",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('ユーザーアカウントをお持ちの方'),expression:"'ユーザーアカウントをお持ちの方'"}],staticClass:"p-authSection__title"}),_c('div',{staticClass:"p-authInputs"},[_c('PAuthInput',{staticClass:"p-authInputs__input",attrs:{"type":"email","placeholder":_vm.$t('メールアドレス'),"autocomplete":"email","required":"","is-error":_vm.usernameError},model:{value:(_vm.$v.username.$model),callback:function ($$v) {_vm.$set(_vm.$v.username, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.username.$model"}}),_c('PAuthInput',{staticClass:"p-authInputs__input",attrs:{"type":"password","placeholder":_vm.$t('パスワード'),"autocomplete":"current-password","required":"","is-error":_vm.passwordError},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}}),(_vm.isError)?_c('p',{directives:[{name:"t",rawName:"v-t",value:(
              'メールアドレスもしくはパスワードが間違っています。再度入力してください。'
            ),expression:"\n              'メールアドレスもしくはパスワードが間違っています。再度入力してください。'\n            "}],staticClass:"p-authInputs__errorText"}):_vm._e()],1),_c('div',{staticClass:"p-authSection__checkbox"},[_c('CCheckbox',{model:{value:(_vm.keepLogin),callback:function ($$v) {_vm.keepLogin=$$v},expression:"keepLogin"}},[_vm._v(_vm._s(_vm.$t('ログイン状態を保持')))])],1),_c('div',{staticClass:"p-loginButtons"},[_c('button',{directives:[{name:"t",rawName:"v-t",value:('ログイン'),expression:"'ログイン'"}],staticClass:"p-authSection__button p-loginButtons__button c-button",attrs:{"type":"submit","disabled":_vm.$v.$invalid}}),_c('div',{staticClass:"p-authSection__link p-loginButtons__link"},[_c('app-link',{directives:[{name:"t",rawName:"v-t",value:('パスワードをお忘れの方はこちら'),expression:"'パスワードをお忘れの方はこちら'"}],staticClass:"c-link",attrs:{"to":{ name: 'PasswordReset' }}})],1)])])]),_c('div',{staticClass:"p-login__column"},[_c('section',{staticClass:"p-authSection"},[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('初めて利用する方'),expression:"'初めて利用する方'"}],staticClass:"p-authSection__title"}),_c('div',{staticClass:"p-loginDetail content"},[_c('p',{directives:[{name:"t",rawName:"v-t",value:({
              path:
                '{siteName}を初めて利用する方はユーザー登録が必要です。\n以下のユーザー登録ボタンを押して、メールアドレスとパスワードを設定してください。',
              args: {
                siteName: _vm.$t(`${_vm.portalId}.おみせのトラスト`),
              },
            }),expression:"{\n              path:\n                '{siteName}を初めて利用する方はユーザー登録が必要です。\\n以下のユーザー登録ボタンを押して、メールアドレスとパスワードを設定してください。',\n              args: {\n                siteName: $t(`${portalId}.おみせのトラスト`),\n              },\n            }"}]})]),_c('div',{staticClass:"p-loginButtons"},[_c('app-link',{directives:[{name:"t",rawName:"v-t",value:('新規登録'),expression:"'新規登録'"}],staticClass:"p-authSection__button p-loginButtons__button c-button",attrs:{"to":_vm.toSignupRoute}})],1)])])]):_c('div',{staticClass:"p-authContainer"},[_c('form',{staticClass:"p-authSection",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('ログイン'),expression:"'ログイン'"}],staticClass:"p-authSection__title"}),_c('div',{staticClass:"p-authInputs"},[_c('PAuthInput',{staticClass:"p-authInputs__input",attrs:{"type":"email","placeholder":_vm.$t('メールアドレス'),"autocomplete":"email","required":"","is-error":_vm.usernameError},model:{value:(_vm.$v.username.$model),callback:function ($$v) {_vm.$set(_vm.$v.username, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.username.$model"}}),_c('PAuthInput',{staticClass:"p-authInputs__input",attrs:{"type":"password","placeholder":_vm.$t('パスワード'),"autocomplete":"current-password","required":"","is-error":_vm.passwordError},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}}),(_vm.isError)?_c('p',{directives:[{name:"t",rawName:"v-t",value:(
            'メールアドレスもしくはパスワードが間違っています。再度入力してください。'
          ),expression:"\n            'メールアドレスもしくはパスワードが間違っています。再度入力してください。'\n          "}],staticClass:"p-authInputs__errorText"}):_vm._e()],1),_c('div',{staticClass:"p-authSection__checkbox"},[_c('CCheckbox',{model:{value:(_vm.keepLogin),callback:function ($$v) {_vm.keepLogin=$$v},expression:"keepLogin"}},[_vm._v(_vm._s(_vm.$t('ログイン状態を保持')))])],1),_c('button',{directives:[{name:"t",rawName:"v-t",value:('ログイン'),expression:"'ログイン'"}],staticClass:"p-authSection__button p-loginButtons__button c-button",attrs:{"type":"submit","disabled":_vm.$v.$invalid}}),_c('div',{staticClass:"p-authSection__link"},[_c('app-link',{directives:[{name:"t",rawName:"v-t",value:('パスワードをお忘れの方はこちら'),expression:"'パスワードをお忘れの方はこちら'"}],staticClass:"p-authSection__link c-link",attrs:{"to":{ name: 'PasswordReset' }}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }