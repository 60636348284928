<template>
  <article class="l-container">
    <div class="p-login" v-if="isSecurityUserAccess">
      <div class="p-login__column">
        <form class="p-authSection" @submit.prevent="handleSubmit" novalidate>
          <h2
            class="p-authSection__title"
            v-t="'ユーザーアカウントをお持ちの方'"
          />
          <div class="p-authInputs">
            <PAuthInput
              class="p-authInputs__input"
              type="email"
              v-model.trim="$v.username.$model"
              :placeholder="$t('メールアドレス')"
              autocomplete="email"
              required
              :is-error="usernameError"
            />
            <PAuthInput
              class="p-authInputs__input"
              type="password"
              v-model.trim="$v.password.$model"
              :placeholder="$t('パスワード')"
              autocomplete="current-password"
              required
              :is-error="passwordError"
            />
            <p
              v-if="isError"
              class="p-authInputs__errorText"
              v-t="
                'メールアドレスもしくはパスワードが間違っています。再度入力してください。'
              "
            />
          </div>
          <div class="p-authSection__checkbox">
            <CCheckbox v-model="keepLogin">{{
              $t('ログイン状態を保持')
            }}</CCheckbox>
          </div>
          <div class="p-loginButtons">
            <button
              type="submit"
              class="p-authSection__button p-loginButtons__button c-button"
              v-t="'ログイン'"
              :disabled="$v.$invalid"
            />
            <div class="p-authSection__link p-loginButtons__link">
              <app-link
                class="c-link"
                :to="{ name: 'PasswordReset' }"
                v-t="'パスワードをお忘れの方はこちら'"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="p-login__column">
        <section class="p-authSection">
          <h2 class="p-authSection__title" v-t="'初めて利用する方'" />
          <div class="p-loginDetail content">
            <p
              v-t="{
                path:
                  '{siteName}を初めて利用する方はユーザー登録が必要です。\n以下のユーザー登録ボタンを押して、メールアドレスとパスワードを設定してください。',
                args: {
                  siteName: $t(`${portalId}.おみせのトラスト`),
                },
              }"
            />
          </div>
          <div class="p-loginButtons">
            <app-link
              :to="toSignupRoute"
              class="p-authSection__button p-loginButtons__button c-button"
              v-t="'新規登録'"
            />
          </div>
        </section>
      </div>
    </div>

    <div v-else class="p-authContainer">
      <form class="p-authSection" @submit.prevent="handleSubmit" novalidate>
        <h2 class="p-authSection__title" v-t="'ログイン'" />
        <div class="p-authInputs">
          <PAuthInput
            class="p-authInputs__input"
            type="email"
            v-model.trim="$v.username.$model"
            :placeholder="$t('メールアドレス')"
            autocomplete="email"
            required
            :is-error="usernameError"
          />
          <PAuthInput
            class="p-authInputs__input"
            type="password"
            v-model.trim="$v.password.$model"
            :placeholder="$t('パスワード')"
            autocomplete="current-password"
            required
            :is-error="passwordError"
          />
          <p
            v-if="isError"
            class="p-authInputs__errorText"
            v-t="
              'メールアドレスもしくはパスワードが間違っています。再度入力してください。'
            "
          />
        </div>
        <div class="p-authSection__checkbox">
          <CCheckbox v-model="keepLogin">{{
            $t('ログイン状態を保持')
          }}</CCheckbox>
        </div>
        <button
          type="submit"
          class="p-authSection__button p-loginButtons__button c-button"
          v-t="'ログイン'"
          :disabled="$v.$invalid"
        />
        <div class="p-authSection__link">
          <app-link
            class="p-authSection__link c-link"
            :to="{ name: 'PasswordReset' }"
            v-t="'パスワードをお忘れの方はこちら'"
          />
        </div>
      </form>
    </div>
  </article>
</template>

<script>
import PAuthInput from '@/components/auth/PAuthInput.vue'
import CCheckbox from '@/components/common/CCheckbox.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'LoginPresentation',
  components: { PAuthInput, CCheckbox },
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      username: '',
      password: '',
      keepLogin: false,
      emptyError: false,
    }
  },
  validations: {
    username: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    usernameError() {
      return this.$v.username.$error || this.isError
    },
    passwordError() {
      return this.$v.password.$error || this.isError
    },
    isSecurityUserAccess() {
      return (
        this.$route.query.isSecurityUserAccess && this.$route.query.redirect
      )
    },
    toSignupRoute() {
      const { redirect } = this.$route.query

      return {
        name: 'Signup',
        query: {
          redirect,
        },
      }
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('signin', {
        username: this.username,
        password: this.password,
        keepLogin: this.keepLogin,
      })
    },
  },
}
</script>
