<template>
  <label class="c-checkbox">
    <input
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
    />
    <span class="c-checkbox__symbol" />
    <span class="c-checkbox__label">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'CCheckbox',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
